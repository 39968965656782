:root {
    --padding: 2%; /* Must be a percentage value if you want IE10 compatability */
    --margin: 1%; /* Must be a percentage value if you want IE10 compatability */
    --vertical-padding: 2em; /* must NOT be a percentage value */
    --vertical-margin: 2em; /* must NOT be a percentage value */
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 800px;
    --container-max-width: 1100px;
    --fixed-header-height: 80px;
}

@import "/theme/intendit/css/modules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/theme/intendit/css/modules/basic_styling.css";
@import "/theme/intendit/css/modules/flex_grid.css";
@import "/theme/intendit/css/modules/slick.css";
@import "/theme/intendit/css/modules/menu.css";
@import "/theme/intendit/css/modules/font-awesome.4.5.0.css";
@import "/theme/intendit/css/modules/magnific-popup.css";

/*
@import "/theme/intendit/css/modules/photoswipe.css";
@import "/theme/intendit/css/modules/animate.css";
*/

/*----------------------------------------------------------------GENERAL------------------------------------------------------------------------*/
body {
    font-weight: 300; 
    color: #404040;
    font-family: 'Roboto Condensed', sans-serif;
    background:#fff /*url('/theme/intendit/graphics/bg.jpg') center top/cover no-repeat fixed;*/; 
    line-height:1.4em;
    font-size: 1em;}
 
.grundfos {position:absolute; top:20px; right:20px; }
.grundfos img {width:155px; height:auto; }

/*---------------------------------------------------------------DIVERSE "WRAPPERS"------------------------------------------------------------*/
.wrapper { min-height: 100vh; max-width:1200px; margin: 0 auto; background:#e4e4e4; padding-bottom: 30px; } /*EGEN DIV by EMMA - puttar ner sidfoten*/
.startsida .wrapper {min-height:1em; }
.sida {padding: 30px 0 50px; } /*EGEN DIV by EMMA - wrappar allt content*/
.container {width: 100%; max-width: 1100px; padding: 0; }
.containerlrg {width: 100%; max-width: 1300px; padding: 0; margin: 0 auto; }
.record {padding: 20px 0; border-bottom:1px solid #e4e4e4; }

 
/*---------------------------------------------------------------BASIC ELEMENTS----------------------------------------------------------------*/
a {color:#404040; font-weight:300; text-decoration: none;}
a:hover {color:#404040;  }

p {margin:3px 0 20px;}
strong {font-weight:500;}
iframe {max-width:100%;}

/*-------------------images--------------------*/
img {max-width:100%; height:auto;}
.bild {float:right; width:360px;  max-width:40%; height:auto;  margin: 0 0 30px 30px; overflow:hidden; }
.box_bild, .listing_bild {width:100%; flex:none; max-width:100%;} /*säkra vyn i IE*/
.bild img, .box_bild img, .listing_bild img, .galleri_bild img {width:100%; height:auto; } /*säkra vyn i IE*/

/*-------------------listor--------------------*/
.sida ul {list-style:disc; padding: 0 0 0 15px; }
.sida ul li {margin-bottom:3px;  }
/*.sida ul {list-style:none; padding: 0; }
.sida ul li:before {content: "\f00c"; margin:0 10px 0 0; font-family: FontAwesome;}*/
.box_textdiv ul  {list-style:none; padding: 0; }
.box_textdiv ul li:before {content: "\f00c"; margin:0 10px 0 0; font-family: FontAwesome;}
.box_textdiv ul li {width:50%; float:left;}

/*------------readmore & buttons----------------*/
.readmore, .button {background: rgba(0, 0, 0, 0.15); color:#404040; transition:0.5s ease-in 0s; font-weight:400; margin-top:10px;  }
.readmore:hover, .button:hover {background:rgba(0, 0, 0, 0.3); color:#404040; transition:0.5s ease-in 0s;}
.readmore {padding: 5px 20px; display:table; } 
.button {padding: 8px 0px; display:block; border:none; height:auto; letter-spacing:0; line-height:1.2em; text-transform: none;}

/*-----------------ikoner-----------------------*/
.fa {font-size:1.5em;}
.fa.round {border-radius: 50%; padding: 11px 0 0; text-align: center; width: 45px; height: 45px; background: #404040; color:#fff;}
.fa.circle {border-radius: 50%; padding: 13px 0 0; text-align:center; width: 50px; height: 50px; border: 1px solid #404040; }
.sidfot .fa.round {background:rgba(255, 255, 255, 0.2); font-size:1.3em; width: 40px; height: 40px; padding: 10px 0 0;  }
.box .fa {display:block; margin-bottom:15px; text-align:center; }
.toptext .fa {}

/*-------------egna klasser---------------------*/
.spaltX2 {column-count: 2; column-gap: 50px; text-align:left !important;} /*Delar upp i 2 spalter*/
.shadow {box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3) }
.shadowborder {box-shadow: 0px 0px 0px 1px #e4e4e4;}
.vcenter_item {position: relative; top: 50%; transform: translateY(-50%);}
.pubdatum {color:#909090; font-size:95%; display:block; clear:both;  }

/*---------------googlekarta----------------------*/
.map-canvas {height: 35vh; margin-top:2em;}
.map-canvas img {max-width: none;}
.kontakt_sektion .map-canvas {height: 45vh;}

/*---------------------------------------------------------------FONTS------------------------------------------------------------------------*/
h1, h2, h3, h4, h5, h6 {margin: 0; letter-spacing:0; font-weight:400;  }

h1{font-size: 2.2em; line-height:1.1em; padding: 0px 0 20px; /*text-transform:uppercase;*/}
h2{font-size: 1.7em; line-height:1.1em; padding: 0px;}
h3{font-size: 1.3em; line-height:1.1em; padding: 0px;}
h4{font-size: 1em; line-height:1.1em;}
h5{font-size: 1em; line-height:1.1em;}

.highlight {font-size:4em; line-height:1.2em; padding: 20px 0 ; color:#e4e4e4; font-weight:300; }
.sida.listing h1 {padding-bottom:40px; margin-bottom:40px; border-bottom:1px solid #e4e4e4;}

/* Sliding border under text
.box h2 {padding-bottom: 35px; position: relative; text-align: center;}
.box h2:after {content:""; position:absolute; background:#404040; height:3px; width:20px; margin: 45px 0 0; left:50%; transition:0.5s ease-in 0s; }
.box:hover h2:after { width:150px;  margin:50px 0 0 -75px; transition:0.5s ease-in 0s; }*/


/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*----------------------------------------------------TOPBILD & TOPTEXT------------------------------------------------------------------------*/ 
.toptext { position:absolute; right:10px; top:90px; display:none;  } /*display none by default*/
.topbild {height:350px;  max-height:45vw;  background: url('/theme/intendit/graphics/top.jpg') center center/cover no-repeat; position: relative;}


/*--------------------------------------------------------------BILDSPEL------------------------------------------------------------------------*/
.slick-slider {margin:0px auto 0;  position: relative; }
.slick-track {height:350px; max-height:45vw; }
.slick-slide {background-size:cover; background-position: center center; display: flex !important; align-items: center; justify-content: center; }   

/*------------------------------------------------------------------STARTBANNER------------------------------------------------------------*/
.startsidatext {padding: 15px 0; width:100%; color:#fff; background:#174483;   }
.startsidatext .twelve {margin-bottom:0; }
.startsidatext h1 {font-size: 1.7em;
    letter-spacing: 2pt;
    padding: 0;
    text-align: center;
    text-transform: uppercase;}
.startsidatext_textdiv {text-align:center; } 

/*------------------------------------------------------------------VANLIGA BOXAR----------------------------------------------------------*/
.boxar {padding: 30px 0 20px; }
/*.boxar > .padding > * { padding-top:0; padding-bottom:0; }*/
.box h3 { font-size:1.6em; padding:10px 0 10px; margin: 0 0 10px; border-bottom:2px solid #d0d0d0; }
.box_textdiv {}
.box_textdiv p:last-child {margin-bottom:0px; }
/*-----------------------------------------SLIDE OUT----------------------------------*/

#slideout {
    background: rgba(0,0,0,.9);
    box-shadow: 0 0 5px rgba(0,0,0,.3);
    color: #333;
    position: fixed;
    top: 50%;
    z-index: 10000;
    transition: transform .3s;
    transform: translateX(-145%) translateY(-50%);
}



#slideout.on {
  transform: translateX(-45%) translateY(-50%);
}

/*--- SlideOut-Knappen ----*/
.show-slide {
    position: absolute;
    top: 50%;
    right: 0px;
    z-index: 10000;
    transform: rotate(90deg) translateY(-280%) translateX(-15%);
    color:#FFFFFF;
    border:none;
    border-radius:3px 3px 0px 0px ; 
    margin-right: 29px;
    padding: 10px 30px;
    opacity:0.4;
    background: #29538e;
    transition: opacity 0.5s; 
}

.show-slide:hover {
    opacity:0.8;
}
 

.slideoutwrapper {
    padding:2em;
    max-height:100vh;
    max-width: 520px;
    overflow: auto;
    background: #29538e;
    color: #fff;
}

.news-block {
    top: 20em;
}

.news-block .buttonfbreadmore {
    padding: 8px 12px;
    color: #FFF;
    background: #666;
    text-decoration: none;
    border-radius: 5px;
    display: inline-block;
    margin: 0px 0px 20px 0px;
    text-align: center;
}

.news-block img {
    display: block;
    margin-top: 4vh;
}





/*---------------------------------------------------------------GALLERI ------------------------------------------------------------------*/
.magnificgalleri {padding: 40px 0 0; margin-top:40px; border-top: 1px solid #fff; }
.galleri_bild img {padding:10px;}
.thumbtitel {font-size:90%; }  
.mfp-bg {background: #000; opacity:0.9;}
 
/*---------------------------LOGOGALLERI ------------------------------------------*/
.wrapper .loggagalleri { background: #ffffff; padding: 30px 0; }
.loggagalleri h3{ font-size:1.4em; padding-bottom: 30px; }
.logocontainer {width:100%; max-width: 800px; margin: 0 auto;  }
.loggagalleri .logga {min-height: 70px; padding-top:0; padding-bottom:0; }
.loggagalleri .logga img {height:auto; flex:0 0 auto;}
.standing  {width:40%; } .squarish  {width:40%; } .landscape  {width:55%; }.longlandscape {width:70%; }.extralonglandscape {width:100%; }
 
/*------------------------------------------------------------------Formulär------------------------------------------------------------------*/
.boltforms-row label {font-weight: 400; margin-bottom: 0;}
.boltforms-row input {width:100%; color:#606060;  }
.boltforms-row textarea {width:100%; color:#606060; }
.boltforms-row button { display:block; width:100%; color:#fff; background-color: rgba(0, 0, 0, 0.2);  margin:0; 
border: none; border-radius: 2px; height:auto; padding: 10px 20px; font-weight:400; font-size:1em; line-height: 1.1em; transition:0.5s ease-in 0s;}
.boltforms-row button:hover { background-color: rgba(0, 0, 0, 0.4); transition:0.5s ease-in 0s; color:#fff;}


/*Kontaktbanner*/
.kontaktbanner { background: #29538e; padding:50px 0;  color:#fff;   box-sizing: border-box; max-width:1200px; margin: 0 auto;   }
.kontaktbanner h2 { padding:0px 0 10px; }
.kontaktbanner .boltforms-row {float:left; width:33.3%; padding-right:10px;}
.kontaktbanner .boltforms-row:nth-child(n+4) {width:100%;}

/*Kontaktformulär
.kontaktformular .boltforms-row {width:100%;}*/

/* --------------------------------------------------------------------TABLES--------------------------------------------------------------------
table { margin:30px 0; font-size:95%; }
table tr {width:100%; border-bottom: 1px solid #e4e4e4;}
table tr:first-child {}
table tr td { margin: 0; padding:5px 30px 5px 0; vertical-align: top; border:none; }
table tr td:last-child {padding:5px 0px 5px 0;}
table tr td h1, table tr td h2, table tr td h3 {padding-top:0; margin-top:0;}*/


/*---------------------------------------------------------------FOOTER SPECIFIC----------------------------------------------------------------*/
.sidfot { padding: 50px 0 200px; width:100%; background:#101010; color:#b0b0b0; max-width:1200px; margin: 0 auto;   }
.sidfot a {color:#b0b0b0; font-weight:300; }
.sidfot h3 {padding: 0 0 5px;} 


/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*----------------------------------------------------------------HEADER SPECIFIC---------------------------------------------------------------*/
header {text-transform: none; width:100%;  z-index:10;}
body.scrolled header{}
body.scrolled header .logo img {}
body.scrolled header nav {}

/*Logotype*/
.logo {margin:0; padding:10px 0 10px 0px; box-sizing:border-box; } /*Add width:100% för att ge egen "rad"*/
.logo img {max-height: 45px; width:auto;}
.logo a {padding:0; color:#404040; font-size:1.8em;} /*fontsize för att over-ride "extra" höjd på loggan*/


/*Standard meny + hovers*/
nav {padding:10px 0; z-index:10;} 
nav ul.menu li {padding: 0 10px; margin:0; border-right:1px solid #e4e4e4; }
nav ul.menu li:last-child {border:none; }
nav ul.menu li.dropdown > a:after { content: " \f107"; display: inline-block; font-family: FontAwesome; font-size: 1em; margin: 0 0 0 5px; vertical-align: middle;}
nav ul.menu li a { padding: 4px 5px; letter-spacing:0; transition:0.5s ease-in 0s;}
nav ul.menu li a:hover { transition:0.5s ease-in 0s; }
nav ul.menu .active a, nav ul.menu .current a { }

/*Dropdown + hover*/
nav .menu .dropdown ul  {border:none; padding: 10px 0; background:#e4e4e4; left:20px;  }
.menu .dropdown li {border:none; }

/*Mobile menu under 800px - fixed header*/
.mobile-menu header {position:fixed; top:0; min-height:80px; border-bottom: 1px solid #e4e4e4; box-shadow:none; background:#fff; } /*background på header bar*/
.mobile-menu .wrapper  {margin-top:80px}  /*knuffar ner innehåll bakom menyn - justera höjd*/
.mobile-menu .logo {text-align:left; padding: 20px 100px 20px 20px}
.mobile-menu .logo img {max-height:40px; max-width:100%; height:auto;}
.mobile-menu .logo a {}
#menu-icon { height: auto; margin: 0; padding: 0;  position: absolute; right: 20px; top: 20px; z-index: 10;}
#menu-icon span { border: 2px solid #000; border-radius:3px;}

.mobile-menu nav ul.menu {background:rgba(255, 255, 255, 0.95); }
.mobile-menu nav ul.menu.show  {max-height:calc(100vh - 80px);  }  /*räknar höjd - fixed header*/
.mobile-menu nav ul.menu li {background:none; }
.mobile-menu nav .menu .dropdown ul {background:none; padding:0; left:0px;} 
.mobile-menu nav ul.menu li.dropdown > a:after {display:none; }
.mobile-menu nav ul.menu li {border:none; }

/*----------------Omifall egen menu-icon 40x30px--------------
#menu-icon span {border:none; }
#menu-icon  {background-image: url("/theme/intendit/icons/mobile-menu-icon.png"); background-position: center right; background-repeat: no-repeat;
background-size: 100% auto; height: 30px; width: 40px;}*/

 

 
/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*-------------------------------------------------------------------MEDIA QUICKIES-------------------------------------------------------------*/
/*----------------------------Allt under 1300px----------------------------*/
@media (max-width: 1300px) 
{
/*fade & zoombox*/
.fadebox, .zoombox {height:23vw}
    
/* Standard meny - tight it up*/ 
nav ul.menu li {padding:0 5px; }  
nav ul.menu li:last-child {padding-right: 10px; }     
nav ul.menu li a {} 
    
}


/*----------------------------Allt under 1100px----------------------------*/
@media (max-width: var(--breakpoint-m))
{
/*container width*/
.container {width: 98%;}
    
     header.container {width:100%; }
       
/*fade & zoombox*/
.fadebox_textdiv, .zoombox_textdiv span {display:none;}  

}
/*----------------------------Allt under 800px----------------------------*/
@media (max-width: var(--breakpoint-s))
{
   
    
/*Fontsize*/ 
body { line-height:1.25em; font-size: 0.97em; } 
    
/*Bild*/
.bild { margin: 0 0 20px 20px;  }
    

/*Fade & Zoomboxar - 100% bredd*/
.fadebox, .zoombox {height:30vw;}
.fadebox_innerdiv {background:rgba(0, 0, 0, 0.2); opacity:1; }
.fadebox_innerdiv.vcenter {display:block; align-items:stretch; justify-content: flex-start; padding-top:7vw }   
.zoombox_innderdiv {transform:none;}
.zoomboxar > a:hover .zoombox_innerdiv {transform:none;} 
.zoombox_textdiv.vcenter {display:block; align-items:stretch; justify-content: flex-start; padding-top:12vw }      
    
    
/*Images*/      
    
/*Sidfot*/   
.sidfot .s-twelve {border-bottom: 1px solid #222222;margin: 0 auto;max-width: 400px; padding: 20px 0;text-align: center; }
.sidfot .s-twelve:last-child {border-bottom: none; }   
}


/*---------------------------MEDIA XS - upp till 550-----------------------------*/
@media (max-width: var(--breakpoint-xs))
{
  
/*fade & zoomboxar*/
.fadebox, .zoombox {height:50vw;}  
       
/*spalt*/    
.spaltX2 {-webkit-column-count: 1; -moz-column-count: 1; column-count: 1; -webkit-column-gap: 0px; -moz-column-gap: 0px; column-gap: 0px; text-align:none;} 
   
/*Kontaktbanner*/   
.kontaktbanner .boltforms-row {width:100%; padding-right:0px;}

/*gallerithumbs*/
.galleribild {border-bottom: 1px solid #e4e4e4; border-right: 1px solid #e4e4e4;} 
.galleribild:nth-child(2n+2) {border-right: none;}
         
/*map*/
.map-canvas {width:100%; max-height:200px; }
    
/*table varje cell under varann med border efter varje rad 
table tr td {display: list-item; list-style: none; padding:0; }
table tr td:first-child {padding: 10px 0 0;}
table tr td:last-child {padding: 0 0 10px;}*/ 
    
}


@media (max-width: 350px)
{
/*bild*/
.bild {width:100%; max-width:100%; margin: 0 0 10px; float:none; }     
.bild img {width:100%; height:auto;}    

}


/*-------------------------------------------------------------------END MEDIA QUERYS--------------------------------------------------------------------*/





/*------------------------------------------------------Make FB widget responsive-------------------------------------------------------*/
.fb_iframe_widget, .fb_iframe_widget span, .fb_iframe_widget iframe[style], .twitter-timeline {width: 100% !important;}
